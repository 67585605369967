// -------------------------- URL relative à Sophia ----------------------------- //
// ------------------------------------------------------------------------------ //

export const SOPHIA_ROOT_URL = "/api/v1.1/sophia/"
export const url_sewan_change_organization = "api/v1.0/switch_organization/"

export const sewan_search = SOPHIA_ROOT_URL + "search_get_all_persons_id/"
export const url_sewan_get_relations = SOPHIA_ROOT_URL + "get_relation/"
export const url_sewan_get_parent_site = SOPHIA_ROOT_URL + "get_sites_from_parent/"

// ------------------------------------------------------------------------------ //
// ------------------------ Variables utiles pour Sophia------------------------- //
// ------------------------------------------------------------------------------ //


//NEW
export const statusInfoMobileSimCardManagement = {
  'used': 'Utilisé',
  'settingInProgress': 'Paramétrage en cours',
  'rejected': 'Rejeté',
  'terminated': 'Résiliation',
  'suspended': 'Suspendue',
  'restricted': 'Restreinte',
  'terminationInProgress': 'Résiliation en cours',
  'reactivationInProgress': 'Réactivation en cours',
  'suspensionInProgress': 'Suspension en cours',
  'restrictionInProgress': 'Restriction en cours',
  'cancelled': 'Annulé',
}

export const statusInfoSimCard = {
  'active': 'Assignée',
  'free': 'Libre',
  'substituted': 'Substituée',
  'swapped': 'Substituée',
  'swap_failed': 'Substitution ratée',
  'substitutionInProgress': 'Substitution en cours',
  'substitutionFailed': 'Échec de substitution',
  'blocked': 'Bloquée',
  'in_progress': 'En cours',

}
//FIN NEW
// Link_state transcription ENTRYPOiNT : MobileSIMCardManagement //
export const linkStateMobileSimCardManagement = {
  'unlinked': 'Libre',
  'primary': 'Affectée à une ligne',
  'swapping': 'Substitution en cours',
  'swapped': 'Substituée',
  'swapping_failure': 'Échec substitution',
}

export const json_last_order_type = {
  "MODIFICATION": "Modification",
  "SWAP_SIM": "Changement SIM"
}

export const sewan_type = {
  'Client' : 'organization',
  'Sites' : 'site',
  'Utilisateur' : ['mobileline', 'MOBILELINE', 'identity'],
}
// ID parent ordago //
export const ordagoParentID = 152800
