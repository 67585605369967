import React, { useState, useContext } from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {
  FormControlLabel, Icon,
  IconButton,
  Menu as MuiMenu,
  MenuItem,
  Switch,
  Typography,
  useMediaQuery,
  Grid,
  Button, Backdrop, Fade, Modal
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import WbSunnyRoundedIcon from "@material-ui/icons/WbSunnyRounded";
import Brightness3RoundedIcon from "@material-ui/icons/Brightness3Rounded";
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';

import {ThemeContext} from "services/providers/theme-provider";
import {AuthContext} from "services/providers/auth-provider";
import {LogoSwitchAccount} from "../../logo/logo";
import Cookies from "js-cookie";
import {changeOrganization} from "../../../services/sewan/useSewan";


const useStyles = makeStyles((theme) => ({
  exitIcon: {
    marginRight: theme.spacing(1)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: 400
  },
  cssRoot: {
    color: '#000',
    width: 120,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#e6e3e4',
    },
  }
}));

const MenuControls = (props) => {
  const [open, setOpen] = useState(null);
  const [openSwitch, setOpenSwitch] = useState(false);
  const {isApiModeTest, checkedModeDisabled, checkedModeTest, toggleChecked, parentAccount} = props
  const {themeName, setThemeName} = useContext(ThemeContext);
  const {isAuthenticated, logout} = useContext(AuthContext);
  const [accountSwitch, setAccountSwitch] = React.useState([]);
  const [organizations, setOrganizations] = useState(localStorage.getItem("organizations") && JSON.parse(localStorage.getItem("organizations")))
  const [organizationsIsOpen, setOrganizationsIsOpen] = useState(false)
  const [actualSite, setActualSite] = useState(localStorage.getItem("actual_organization") ? JSON.parse(localStorage.getItem("actual_organization")): "")
  const [backgroundHover, setBackgroundHover] = useState([false, false, false])

  React.useEffect(() => {
    let account_switch = (Cookies.get('account_switch') && Cookies.get('account_switch') !== undefined)? JSON.parse(Cookies.get('account_switch')) : []
    if (account_switch.length > 0 && accountSwitch.length === 0) {
      setAccountSwitch(account_switch)
    }
  }, [accountSwitch])

  const handleClick = event => {
    setOpenSwitch(true);
  };

  const handleClose = () => {
    setOpenSwitch(null);
  };

  const classes = useStyles();
  const theme = useTheme();

  const handleThemeChange = () => {
    if (themeName === "dark") {
      setThemeName("light");
    } else {
      setThemeName("dark");
    }
  }

  const swithApiComponent = () => {
    return (<FormControlLabel
      control={
        <Switch
          size="small"
          disabled={checkedModeDisabled}
          checked={checkedModeTest}
          onChange={toggleChecked}
        />}
      label={"Api [Mode test]"}
    />)
  }

  const bugReport = () => {
    let urlBugReport = 'https://forms.monday.com/forms/198daa4553ba81f902e715eb7a35bbed?r=use1'
    return (
      <>
        <IconButton color="secondary" aria-label="Bug report" href={urlBugReport} target='_blank'>
          <Icon>bug_report</Icon>
        </IconButton>
      </>
    )
  }

  const changeBackground = ( index, value ) =>{
    setBackgroundHover((oldBackgroundHover) => {
      const tempoBackground = [...oldBackgroundHover].map(() => false);
      tempoBackground[index] = value;
      return tempoBackground;
    });
  }

  const SwitchAccount = () => {
    return (
      <>
        {parentAccount.picto != null && accountSwitch.length > 0 && (
          <div  style={{ position: "relative" }}>
            <Button
              variant="contained" color="secondary"
              onClick={handleClick}
              className={classes.cssRoot}
            >
              <img
                style={{paddingRight: 5, height: 31}}
                src={`${process.env.PUBLIC_URL}/logo/color/${parentAccount.picto}`}
              />
              {parentAccount.slug}
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openSwitch}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openSwitch}>
                <div className={classes.paperModal} style={{textAlign: 'center'}}>
                  <LogoSwitchAccount style={{paddingRight: 30, height: 31, cursor: 'grab'}} folder={'color'} AuthContext={AuthContext} accountSwitch={accountSwitch} />
                </div>
              </Fade>
            </Modal>
          </div> )}
      </>
    )
  }

  const modificationOrganization = (organization) => {
    changeOrganization(organization.id).then(() => {
      localStorage.setItem("actual_organization", JSON.stringify(organization))
      setActualSite(() => organization)
      changeBackground(0, false)
    }).finally(() =>{
      setOrganizationsIsOpen(() => false)
    })
  }


  const switchOrganization = () => {
    return (
      <>
        {actualSite !== "" &&
          <Grid style={{position: "relative", width: 270, marginRight: 10}}>
            <Grid style={{display: "flex", flexDirection: "row", alignItem: "center", alignContent: "center", justifyContent: "space-around"}}>
              <Grid style={{marginLeft:0, right: 0, display: "flex", height: 50, background: "white", flexDirection: "row", justifyContent: "space-around", color: "black", borderRadius: 9, width: 200}}>
                <Grid onClick={(e) => {
                  setOrganizationsIsOpen((old) =>!old)
                }}
                      style={{display: "flex", width:30, flexDirection: "column", justifyContent: "center"}}>
                  {/*<p> ICONE </p>*/}
                  <SwapHorizontalCircleOutlinedIcon
                    style={{cursor: "pointer", width: 30, padding: 2.5, height: 45, borderRight: "1px solid #E4E4E4", marginLeft: 5}}/>
                </Grid>
                <Grid style={{display: "flex", position: "relative", alignItem: "center", alignContent: "center",  justifyContent: "center", height: 50, flexDirection: "row"}}>
                  <p style={{textAlign: "center", width: "160px"}}>
                    {actualSite['name']}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            {organizationsIsOpen &&
              <Grid style={{display: "flex", flexDirection: "column", height: "auto", background: '#FFFFFF', boxShadow: "1px 3px 2px #E4E4E4", position: "absolute", color: "black", borderRadius: "5px", width: 230, padding: 5, marginRight: 10, marginTop: 5}}>
                <Grid style={{position: "absolute", top: "-2px", right: 10, transform: "rotate(45deg)", width: 8, height: 8, background: "white"}} />
                <Grid style={{borderBottom: "1px solid grey", marginBottom: "5px"}}>
                  <p style={{paddingLeft: 10, textAlign: "center"}}>Profils de droits</p>
                </Grid>
                {Object.values(organizations).map((item, index) => (
                  <Grid onMouseEnter={() =>{ changeBackground(index, true)}}
                        onMouseLeave={() =>{ changeBackground(index, false)}}
                        style={{display: "flex", cursor: "pointer", background: actualSite.name === item.name ? "#e5f0ff" :backgroundHover[index] ? "#e0e0e0": "white", justifyContent: "center", alignItems: "center", color: "black", height:50}} key={item.id}
                        onClick={(e) => {
                          modificationOrganization(item)
                        }}>
                           <span className="material-icons-outlined" style={{backgroundColor: "white", padding: 5, width:"60", border: "2px solid #2A9D7A", borderRadius: 6, color: "#2A9D7A"}}>
                              maps_home_work
                            </span>
                    <span>
                              <p style={{width: 170, textAlign: "left", paddingLeft: 15, color: "black"}}>{item.name}</p>
                            </span>
                  </Grid>
                ))}
              </Grid>
            }
          </Grid>
        }
      </>
    )
  }


  const themeComponent = (responsive) => {
    return (
      <>
        {responsive ?
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={themeName === "dark"}
                onChange={handleThemeChange}
              />}
            label={"Thème sombre"}
          />
          :
          <IconButton onClick={handleThemeChange} color={"inherit"}>
            {themeName === "dark" ?
              <WbSunnyRoundedIcon/>
              :
              <Brightness3RoundedIcon/>}
          </IconButton>
        }
      </>
    );
  }

  const logoutComponent = (responsive) => {
    return (
      <>
        {responsive ?
          <>
            <ExitToAppRoundedIcon className={classes.exitIcon}/>
            <Typography>Se déconnecter</Typography>
          </>
          :
          <IconButton onClick={() => logout()} color="inherit">
            <ExitToAppRoundedIcon/>
          </IconButton>
        }
      </>
    );
  }

  const controls = [
    {
      display: false,
      component: swithApiComponent,
      menuClick: undefined
    } ,
    {
      display: true,
      component: switchOrganization,
      menuClick: undefined
    },
    {
      display: false,
      component: themeComponent,
      menuClick: undefined
    },
    {
      display: true,
      component: bugReport,
      menuClick: undefined
    },
    {
      display: true,
      component: SwitchAccount,
      menuClick: undefined
    },
    {
      display: isAuthenticated,
      component: logoutComponent,
      menuClick: logout
    }
  ]

  return (
    <>
      {
        !useMediaQuery(theme.breakpoints.up('sm')) ?
          <>
            <IconButton onClick={e => setOpen(e.currentTarget)}>
              <MoreVertIcon/>
            </IconButton>
            <MuiMenu
              anchorEl={open}
              keepMounted
              open={Boolean(open)}
              onClose={_ => setOpen(null)}
            >
              {controls.map((control, i) => (
                control.display &&
                <MenuItem key={i} onClick={_ => control.menuClick ? control.menuClick : setOpen(null)}>
                  {control.component(true)}
                </MenuItem>
              ))}
            </MuiMenu>
          </>
          :
          <>
            {controls.map((control, i) => (
              control.display &&
              <div key={i}>
                {control.component(false)}
              </div>
            ))}
          </>
      }
    </>
  );
}

export default MenuControls;
